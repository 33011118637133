.page_content {
    gap: 10px;
    background: #F5F5F5;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.main_content {
    padding: 40px 80px 0 80px;
    gap: 30px;
}

.search_block {
    padding: 40px 0 0 0;
    gap: 42px;
    border-radius: 12px 0 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}


.bar {
    min-width: 360px;
    max-width: 720px;
    gap: 4px;
    border-radius: 28px 0 0 0;
    border: 1px;
}

/*.job_title_bar {*/

/*}*/

/*.location_bar {*/

/*}*/

.search_button {
    background: #8600CE;
    padding: 0 10px 0 10px;
    gap: 8px;
    border-radius: 100px;
}