.left_bar {
    display: block;
    width: 330px;
    height: 100%;
    padding-top: 30px;
    justify-content: center;
    align-items: flex-start;
    position: sticky;
    top: 0;
    border-right: 1px solid #EEE;
    background: #FFF;
    box-shadow: 1px 0px 9px 2px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
}

.left_back {
    display: flex;
    height: 48px;
    padding: 0px 30px;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    align-self: stretch;
    cursor: pointer;
}

.back_btn_wrapper {
    height: 100%;
    width: fit-content;
    border: none;
    cursor: pointer;
    background: none;
}

.left_menu_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
    margin-top: 50px;
}

.left_menu {
    display: flex;
    padding: 0px 30px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
}

.left_menu_top_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
    align-self: stretch;
}

.arci_gray_text {
    color: var(--color-gray-5);
    font-family: var(--font-arciform-sans);
    font-size: var(--font-size-large);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    margin: 0;

}

.gray_text {
    color: var(--color-gray-6);
    font-family: var(--font-nunito);
    font-size: var(--font-size-regular);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    margin: 0;
}

.left_menu_wizard_items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
}

.topMenuWrapper {
    align-self: flex-start;
}