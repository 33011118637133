.aiIcon1 {
    position: relative;
    width: 18px;
    height: 18px;
    object-fit: cover;
    margin-right: 5px;
  }

.aiIcon1Parent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-10xs);
  }

.conflictResolution
    {
  position: relative;
  color: #8600ce;
  }

.conflictResolution
    {
  position: relative;
  }

.fullQuestionBox {
  display: flex;
}
