
.interview_page {
    display: inline-flex;
    padding: 30px 10px;
    justify-content: center;
    align-items: center;
    background-color: var(--color-gray-1);
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
}

.main_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 42px;
    height: 100%;
    width: 50%; /* adjust when using 2 boxes */
}

.interview_header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
    padding: 0;
}


.interview_header h1 {
    color: var(--color-near-black);
    text-align: center;
    font-family: var(--font-nunito);
    font-size: var(--font-size-large);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 0;
    margin: 0;
}

.interview_header h2 {
    color: var(--color-near-black);
    text-align: center;
    font-family: var(--font-nunito);
    font-size: var(--font-size-large);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
    padding: 0;
    margin: 0;
}

.videos_container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 61px;
    min-height: 330px;
    flex-grow: 2;
    width: 100%
}


.video_box {
    border-radius: 50px;
    box-shadow: 1px 0px 9px 2px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    flex: 1;
    width: 100%;
    height: 100%;
}




.buttons_container {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: end;
    width: 100%;
    margin-top: -30px; 
}

.notes_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
    width: 100%;

}

.notes_container textarea {
    display: flex;
    width: 100%;
    min-height: 90px;
    height: 100%;
    align-items: center;
    box-shadow: 1px 0px 9px 2px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    border-radius: 16px;
    background: white;
    border: none;
    padding: 15px 30px 15px 30px;
    resize: vertical;
    font-family: var(--font-nunito);
    font-size: var(--font-size-regular);
    color: var(--color-near-black);
}

.notes_warning {
    color: var(--color-gray-6);
    font-family: var(--font-nunito);
    font-size: var(--font-size-small);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.attempts {
    color: var(--color-gray-6);
    font-family: var(--font-nunito);
    font-size: var(--font-size-regular);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

#recruiter_video {
    display: none;
}

.final_message h2 {
    color: var(--color-near-black);
    text-align: center;
    font-family: var(--font-nunito);
    font-size: var(--font-size-large);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
    padding: 0;
    margin: 0;
}

.final_message {
    font-family: var(--font-nunito);
    font-size: var(--font-size-regular);
    color: var(--color-near-black);
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.final_message p {
    text-align: center;
    width: 100%;
}

.error_text {
    font-size: var(--font-size-regular);
    font-family: var(--font-nunito);
    color: #F44336;
}

@media only screen and (max-width: 850px) {
    .main_content {
        width: 100%;
    }
}

@media only screen and (max-width: 550px) {
    .interview_page {
        padding: 5px;
    }
}

