.credentialsWrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
}

.menuBottomLogin,
.sideMenuDrawer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.menuBottomLogin {
    align-self: end;
    border-top: 1px solid var(--color-gray-4);
    flex-direction: row;
    padding: var(--padding-xl);
    gap: var(--gap-mid);
    font-size: var(--font-size-regular);
    color: var(--color-near-black);
}

.avatarParent,
.nameEmail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.avatarParent {
    flex: 1;
    flex-direction: row;
    align-items: center;
    gap: var(--gap-6xs);
    min-width: 150px;
}

.nameEmail {
    width: 100px;
    overflow-wrap: anywhere;
}

.avatarIcon {
    position: relative;
    border-radius: 50%;
    width: 44px;
    height: 44px;
    object-fit: cover;
}

.email {
    position: relative;
    font-size: 11.2px;
    color: var(--color-gray-6);
    display: none;
}

.dotMenuChild {
    position: relative;
    border-radius: 50%;
    background-color: var(--color-near-black);
    width: 3px;
    height: 3px;
}

.dotMenu {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 2px;
}

.popup {
    position: fixed;
    width: 200px;
    height: 100px;
    background: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.modalContent {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}