.modalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 647px;
}

.modalContent h2 {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    color: #131138;
    text-align: center;
    font-size: 27.344px;
    font-family: Arciform;
    font-weight: normal;
    padding: 0;
    margin: 0;
}
.modalContent p {
    display: flex;
    width: 100%;
    flex-direction: column;
    color: #777;
    text-align: center;
    font-size: 14px;
    font-family: Nunito;
    padding: 0;
    margin: 0;
}

.modalContent span {
    display: flex;
    flex-direction: column;
    color: #777;
    text-align: left;
    font-size: 14px;
    font-family: Nunito;
}

.modalContent textarea {
    box-sizing: border-box;
    border-radius: 16px;
    background: #EEE;
    width: 100%;
    border: none;
    padding: 30px;
    resize: vertical;
    min-height: 100px;
    font-family: Nunito;
    font-size: 14px;
    color:#131138;
}


.modalContent textarea:active {
    border: #777777 1px;
}

.hints {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.actionButtonsContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    align-self: stretch;
}