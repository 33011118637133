.button_wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center; 
    align-items: center;  /* Align vertically */
    padding-bottom: 40px;
    border: none;
}

.button {
    width: 50px;  /* Adjust size as needed */
    height: 50px;  /* Adjust size as needed */
    background-color: rgba(0, 0, 0, 0.5);  /* Translucent dark */
    border-radius: 50%;  /* Make it circular */
    display: flex;  /* To center the square */
    justify-content: center;  /* Center horizontally */
    align-items: center;  /* Center vertically */
    cursor: pointer;
}

.button::before {
    content: "";
    width: 25px;  /* Adjust size as needed */
    height: 25px;  /* Adjust size as needed */
    background-color: red;  /* Red square */
    display: block;  /* Needed for width and height to work */
}