.button {
    border-radius: var(--br-mini-5);
    padding: 7px;
    padding-left: 25px;
    padding-right: 25px;
    align-items: center;
    justify-content: center;
    color: var(--color-gray-6);
    background-color: var(--color-gray-2); /* Initial gray background color */
    cursor: pointer;
    border: 0;
    width: auto;
    white-space: nowrap;
    font-weight: 100;
}

.selected {
    color: white;
    font-weight: bolder;
    background: linear-gradient(-85.86deg, #f0f, #164193); /* Gradient background for selected state */
}

.button:hover {
    background-color: var(--color-gray-3);
}

.selected:hover {
    background: #f0f;
}