.cell_text {
    color: #333;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.incomplete {
    color: #F44336;
    font-size: 16px;
    font-weight: 500;
}