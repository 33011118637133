.questionSelectionWrapper {
  align-self: stretch;
  gap: 14px;
  flex-direction: row;
  font-size: var(--font-size-regular);
  color: var(--color-white);
  font-family: var(--font-nunito);
  flex-wrap: wrap;
  display: flex;
}

.pageTitle {
  font-size: 34.18px;
  font-family: Arciform, Arial Rounded MT Bold;
  font-weight: normal;
}
.stickyTop {
  z-index: 5;
  background-color: var(--color-white);
  box-shadow: 0 1px 9px 2px rgba(0, 0, 0, 0.15);
  padding: 30px 50px;
  gap: 21px;
  font-size: 34.18px;
  font-family: Arciform, Arial Rounded MT Bold;
  height: fit-content;
  grid-row: 1;
  position: sticky;
  top: 0;
  z-index: 1;
}