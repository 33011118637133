.candidates_page {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    background: #F5F5F5;
    text-align: left;
    font-size: var(--font-size-medium);
    color: var(--color-near-black);
    font-family: var(--font-nunito);
}

.main_content {
    position: relative;
    /* Relative to the page */
    grid-row: 2;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 10px 60px 80px 60px;
    box-sizing: border-box;
}

.candidates_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
}

.candidates_count_section {
    display: flex;
    align-items: center;
    gap: 11px;
}

.candidates_count_section h1 {
    color: #131138;
    font-family: Arciform Sans;
    font-size: 34.18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.candidates_count_section span {
    color: #777;
    font-family: Nunito;
    font-size: 34.18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.filter_section {
    display: none;
}

.aiMatchCellText {
    color: var(--color-purple) !important;
    font-weight: bold !important;
}

.comment_section {
    width: 100%;
}