.table_wrapper {
    width: 100%;
    overflow-x: scroll;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 1px 0px 9px 2px rgba(0, 0, 0, 0.15);
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    height: 90%;

}

/* Custom scrollbar styles */
.table_wrapper::-webkit-scrollbar {
    height: 10px; /* Adjust the height of the scrollbar */
    width: 10px;
}

.table_wrapper::-webkit-scrollbar-track {
    background: #f5f5f5; /* Background color of the scrollbar track */
    border-radius: 8px;

}

.table_wrapper::-webkit-scrollbar-thumb {
    background: #ccc; /* Background color of the scrollbar thumb */
    border-radius: 8px;

}

.table_wrapper::-webkit-scrollbar-thumb:hover {
    background: #aaa; /* Background color of the scrollbar thumb on hover */
}


.table {
    padding: 0;
    margin: 0;
    border-collapse: collapse;
    /* Removes spacing between cells */
    width: 100%;
}

.header_row {
    border-bottom: 1px solid #DDD;
    background: #EEE;
    position: sticky;
    top: 0;
    z-index: 9;
}

.header_row th {
    color: #777;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 15px 15px;
    min-width: 125px;
}

.header_cell_wrapper {
    display: flex;
    flex-direction: row;
    gap: 4px;
}

.header_row > :first-child {
    padding-left: 25px;
}

.header_row > :last-child {
    padding-right: 25px;
}

.table tr {
    overflow: hidden;
}

.row {
    background: #FFF;
    padding-left: 0 25px;
}

.row:hover {
    background-color: var(--color-gray-1);
}

.row > :first-child {
    padding-left: 25px;

}

.row > :last-child {
    padding-right: 25px;
}

.cell {
    border-bottom: 1px solid #DDD;
    /* white-space: nowrap; */
    /* Prevents content from wrapping to the next line */
    width: min-content;
    /* Sets the width based on content size */
    padding: 15px 15px;
    min-width: 125px;
}