.conflictResolutionContainer {
  border-radius: var(--br-mini-5);
  padding: 7px;
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
  justify-content: center;
  color: #777777;
  background-color: #eeeeee; /* Initial gray background color */
  cursor: pointer;
  border: 0;
  width: auto;
  white-space: nowrap;
  font-weight: 100;
}

.conflictResolutionContainer.selected {
  color: white;
  background: linear-gradient(-85.86deg, #f0f, #164193); /* Gradient background for selected state */
}
