.mainWrapper {
    height: 100vh;
    overflow: hidden;
}

h1 {
    color: var(--color-near-black);
    font-family: var(--font-arciform-sans);
    font-size: var(--font-size-huge);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    align-self: stretch;
}


.header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    align-self: stretch;
}

.qualification_controls {
    display: flex;
    align-items: flex-start;
    gap: 22px;
}

.arci_black_text {
    color: var(--color-near-black);
    font-family: var(--font-arciform-sans);
    font-size: var(--font-size-very-large);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    margin: 0;
}

.gray_text {
    color: var(--color-gray-6);
    font-family: var(--font-nunito);
    font-size: var(--font-size-regular);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    margin-top: 0px;
}