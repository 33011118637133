.main_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
}

.smaller_box {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    height: 100%;}

.header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
    padding: 0 70px 0 70px;
}


.header h1 {
    color: var(--color-near-black);
    text-align: center;
    font-family: var(--font-nunito);
    font-size: var(--font-size-large);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 0;
    margin: 0;
}

.content_box {
    border-radius: 50px;
    box-shadow: 1px 0px 9px 2px rgba(0, 0, 0, 0.15);
    width: 100%;
    height: fit-content;
    background: white;
    padding: 50px;

}

.buttons_container {
    width: 100%;
    justify-content: end;
    gap: 10px;
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 800px) {
    .header {
        padding: 0;
    }
}