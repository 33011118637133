.wrapper {
    display: grid;
    place-items: center;
}

.cell_text {
    color: #333;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    text-align: center;
}


.incomplete {
    color: #F44336;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    margin: 0;
}

.gray_button {
    background-color: var(--color-gray-2);
}

.gray_button:hover {
    background: var(--color-gray-3);
}

.gray_button_text {
    color: var(--color-gray-6);
}