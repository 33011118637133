.action_btn_container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-end;
    width: 100%;
    justify-content: end;
    margin-top: 10px;
}

.question {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    color: #131138;
    text-align: center;
    font-size: var(--font-size-medium);
    font-family: Arciform;
    font-weight: normal;
    padding: 0;
    margin: 0;
}

.clarification {
    display: flex;
    width: 100%;
    flex-direction: column;
    color: #777;
    text-align: center;
    font-size: 14px;
    font-family: Nunito;
    padding: 0;
    margin: 0;
}