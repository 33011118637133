.page {
    display: flex;
    padding: 100px 100px 30px 100px;
    justify-content: center;
    align-items: start;
    background-color: var(--color-gray-1);
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
}

.wrapper {
    height: 100%;
    width: 100%;
}

.text {
    color: var(--color-near-black);
    font-size: var(--font-size-regular);
    font-family: var(--font-nunito);
}

.text ul {
    margin: 0;
}

.error_text {
    color: #F44336;
    font-size: var(--font-size-regular);
    font-family: var(--font-nunito);
}

@media only screen and (max-width: 850px) {
    .page {
        padding: 15px 0px 30px;
    }
}