.role_play_page {
    background: #F5F5F5;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    box-sizing: border-box;
    scroll-behavior: unset;
    padding: 60px;

}

.content {
    padding: 60px;

    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 23px;
    box-sizing: border-box;
}

.rp_title {
    align-self: stretch;
    color: var(--color-near-black);
    text-align: center;
    font-family: var(--font-nunito);
    font-size: var(--font-size-very-large);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.content_boxes {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 35px;
    flex: 1 0 0;
    align-self: stretch;
    box-sizing: border-box;
    max-height: 100%;

}

.box_child {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 34px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 50px;
    border-right: 1px solid #EEE;
    background: #FFF;
    box-shadow: 1px 0px 9px 2px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    height: 100%;
}

.instruction_wrapper {
    margin: 45px 60px;
    overflow-y: auto; /* Enables vertical scrolling */
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 34px;

}


.react-chatbot-kit-chat-container {
    height: 100%;
    width: 100%;
}

.instruction_child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.instructions_title {
    align-self: stretch;
    color: var(--color-near-black);
    font-family: Nunito;
    font-size: 17.5px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 0;
    margin: 0;
}

.instructions_paragraph {
    align-self: stretch;
    color: var(--color-near-black);
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0;
    margin: 0;
}

.controls_wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
    align-self: stretch;
}

.msgs_count {
    color: #777;
    font-family: var(--font-nunito);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media only screen and (max-width: 850px) {
    .content_boxes {
        flex-direction: column;

    }

    .role_play_page {
        height: auto;
        padding: 0;
    }

    .content {
        padding: 20px;

    }
    .instruction_wrapper {
        margin: 30px;
    }

  }