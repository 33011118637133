.wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
}

.name_email {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.name {
    color: #333;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    padding: 0;
}

.email {
    color: #333;
    font-family: Nunito;
    font-size: 11.2px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    padding: 0;
}

.circled_avatar {
    border: 2px solid var(--color-purple);
    box-sizing: unset;
}

.circled_avatar .circled_avatar {
    border: none;
}