.wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /*gap: 24px;*/
    width: 100%;
}

.headerText {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    color: var(--color-near-black);
    text-align: center;
    font-size: var(--font-size-very-large);
    font-family: var(--font-arciform-sans);
    font-weight: normal;
    padding: 0;
    margin: 0;
}

.descriptionText {
    display: flex;
    width: 100%;
    flex-direction: column;
    color: var(--color-gray-6);
    text-align: center;
    font-size: var(--font-size-regular);
    font-family: var(--font-nunito);
    padding: 0;
    margin: 0;
}

.hintText {
    display: flex;
    flex-direction: column;
    color: var(--color-gray-6);
    text-align: left;
    font-size: var(--font-size-regular);
    font-family: var(--font-nunito);
}

.textareaContent {
    box-sizing: border-box;
    border-radius: 16px;
    background: var(--color-gray-2);
    width: 100%;
    border: none;
    padding: 15px;
    resize: vertical;
    min-height: 100px;
    font-family: var(--font-nunito);
    font-size: 14px;
    color: var(--color-near-black);
}

.modalContent textarea:active {
    border: var(--color-gray-6) 1px;
}

.hints {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.error_text {
    color: #F44336;
    font-size: var(--font-size-regular);
    font-family: var(--font-nunito);
    margin: 0;
}