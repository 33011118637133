.wrapper {
    display: flex;
    flex-direction: row;
    height: 51px;
    padding: 0px 32px;
    align-items: center;
    gap: 7px;
    align-self: stretch;
    width: 100%;
}

.wrapper input {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    color: #777;
    font-family: Nunito;
    font-size: 17.5px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    box-sizing: border-box;
}