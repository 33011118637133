.menu-item {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    color: #777777;
    align-items: center; /* Aligns the icon and label vertically */
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    font-family: Nunito;
}

.menu-item-label {
    text-decoration: none;
    font-weight: normal;
    font-family: Nunito;

}

.menu-item-icon {
    margin-right: 10px; /* Adds some space between the icon and the label */
    display: flex;
}



.menu-category-title h3{
    font-family: Arciform, Arial Rounded MT Bold;
    font-weight: 200;
    color: #bbbbbb;
    margin-bottom: 20px;
    margin-top: 40px;
}

.menu-category-title {
    width: 100%;
}

.menu-item:hover {
    background-color: #f0f0f0;
    border-radius: 20px; /* Changes the background color on hover */
}

.menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

/*
*/
.menuTop {
    display: flex;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    flex-direction: column;
    gap: 40px;
    align-self: self-start;
    padding-top: 30px;
  }

.dropdownWrapper {
    text-align: center;
}

.copyToClipboard {
    cursor: pointer;
    color: var(--color-purple);
    font-size: var(--font-size-regular);
    margin-top: 10px
}