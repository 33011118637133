.sidebar {
    width: 50px; /* Initial collapsed width */
    height: 100%;
    background-color: #f0f0f0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    transition: width 0.3s; /* Smooth transition */
}

.sidebar:hover,
.sidebar--open {
    width: 200px; /* Width when hovered or open */
}

.sidebar__dropdown,
.sidebar__menu {
    margin: 20px;
}

.sidebar__dropdown-icon,
.sidebar__menu-icon {
    display: none;
}

.sidebar:hover .sidebar__dropdown-icon,
.sidebar:hover .sidebar__menu-icon,
.sidebar--open .sidebar__dropdown-icon,
.sidebar--open .sidebar__menu-icon {
    display: block;
}

.sidebar:hover .sidebar__dropdown-label,
.sidebar:hover .sidebar__menu-label,
.sidebar--open .sidebar__dropdown-label,
.sidebar--open .sidebar__menu-label {
    display: block;
}

.sideMenuDrawer {
    padding: 20px;
    display: grid;
    z-index: 10;
    grid-row: 1 / 3;
    position: sticky;
    height: 100vh;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--color-white);
    box-shadow: 1px 0 9px 2px rgba(0, 0, 0, 0.15);
    width: 273px;
    color: var(--color-gray-6);
    top: 0; /* Align to the top */
    left: 0; /* Align to the left */
    overflow: hidden; /* Enable scrolling if content exceeds the height */
  }


