.wrapper {
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid #BBB;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    flex: 1 0 0;
}

.short_name {
    align-self: stretch;
    color: var(--color-gray-6);
    font-family: var(--font-nunito);
    font-size: var(--font-size-small);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
}

.description {
    align-self: stretch;
    color: var(--color-near-black);
    font-family: var(--font-nunito);
    font-size: var(--font-size-medium);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    border: none;
    border-radius: 0;
    outline: none;
    background: transparent;
    padding: 0;
    margin: 0;
    width: 100%;
    /*line-break: anywhere;*/
    resize: vertical;
    cursor: pointer;
    min-height: 0;
}