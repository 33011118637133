@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arciform+Sans:wght@400&display=swap");

@font-face {
  font-family: 'Arciform';
  src: url('../public/Arciform.otf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  line-height: normal;
}

:root {
  /* fonts */
  --font-nunito: Nunito;
  --font-arciform-sans: Arciform, Arial Rounded MT Bold;

  /* font sizes */
  --font-size-small: 11.2px;
  --font-size-regular: 14px;
  --font-size-medium: 17.5px;
  --font-size-large: 21.875px;
  --font-size-very-large: 27.344px;
  --font-size-huge: 34.18px;

  /* Colors */
  --color-white: #fff;
  --color-gray-1: #f5f5f5;
  --color-gray-2: #eee;
  --color-gray-3: #ddd;
  --color-gray-4: #ccc;
  --color-gray-5: #bbb;
  --color-gray-6: #777;
  --color-gray-7: #333;

  --color-error: red;

  --color-magenta: #FF00FF;
  --color-purple: #8600CE;
  --color-purple2: #AB55D9;
  --color-dark-blue: #413ac2;
  --color-cyan: #00fff;
  --color-green: #32ff01;
  --color-near-black: #131138;

  /* Gaps */
  --gap-mid: 17px;
  --gap-6xs: 7px;
  --gap-xl: 20px;
  --gap-9xl: 28px;
  --gap-lg: 18px;
  --gap-60xl: 79px;
  --gap-3xl: 22px;
  --gap-10xs: 3px;

  /* Paddings */
  --padding-xl: 20px;
  --padding-8xs: 5px;
  --padding-xs: 12px;
  --padding-26xl: 45px;
  --padding-23xl: 42px;
  --padding-3xs: 10px;

  /* border radiuses */
  --br-5xs: 8px;
  --br-mini-5: 14.5px;

  background-color: var(--color-gray-1);
  font-family: var(--font-nunito);
}


/* Proably a bad practice but I am overriding StepZilla styes */
.multi-step {
  width: 100%;
  height: 100%;
}


/*Overriding Chatbot styles*/
.react-chatbot-kit-chat-container {
  width: 100%;
  height: 100%;

}

.react-chatbot-kit-chat-message-container {
  padding: 30px 30px 5px 30px !important;
  overflow-y: scroll;
  overflow-x: auto;
}

.react-chatbot-kit-chat-header {
  display: none;
}

.react-chatbot-kit-chat-bot-message {
  margin-left: 0;
  font-family: var(--font-nunito);
  font-size: var(--font-size-regular);
}

.react-chatbot-kit-chat-bot-message {
  margin-left: 10px;
  background-color: #ececec;
  padding: 15px;
  max-width: 70%;
  width: fit-content;
  border-radius: 20px;
  color: #131138;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
}


.react-chatbot-kit-chat-bot-message-arrow {
  border-right: 8px solid #ececec;
}

.react-chatbot-kit-user-chat-message {
  margin-right: 10px;
  background-color: #FFCDFF;
  padding: 15px;
  max-width: 70%;
  border-radius: 20px;
  color: #131138;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  width: fit-content;

}

.react-chatbot-kit-user-chat-message-arrow {
  border-left: 8px solid #FFCDFF;
}

.react-chatbot-kit-chat-btn-send-icon path {
  fill: #8600CE;
}

.react-chatbot-kit-chat-btn-send-icon path:hover {
  fill: var(--color-magenta);
}


.react-chatbot-kit-chat-btn-send-icon {
  width: 25px;
}


.react-chatbot-kit-chat-btn-send {
  background: #EEE;
  cursor: pointer;
}

.react-chatbot-kit-chat-input {
  background: #EEE;
  color: #131138;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  padding-left: 40px;
  padding-bottom: 20px;
}

.react-chatbot-kit-chat-input:active {
  outline: none;
}

.react-chatbot-kit-chat-input:focus {
  outline: none;
}

.no-display {
  display: none;
}

.react-chatbot-kit-error {

  color: #131138;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
}

.react-chatbot-kit-error-docs {
  display: none;
}

/* Overriding react-avatar styles */

.sb-avatar {
  text-align: center;
  font-family: Nunito;
  font-size: 21.88px;
  font-style: normal;
  font-weight: 540; 
}