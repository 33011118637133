.dropdownContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.dropdownControl {
  display: flex;
  align-items: center;
  gap: 10px;
}

.jobSelection {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  appearance: none;
  padding: 0;
}

.jobSelection option {
  padding: 15px 0;
  border: 1px solid #CCCCCC;
  border-radius: var(--br-5xs);
}

.vectorIcon {
  position: relative;
  width: 21px;
  height: 20.97px;
  margin-right: 10px;
}
