.wrapper {
    gap: 10px;
    display: flex;
    align-items: center;
}

.active {
    cursor: pointer;
}

.text_active {
    color: var(--color-purple);
    font-family: var(--font-nunito);
    font-size: var(--font-size-regular);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.text_inactive {
    color: var(--color-gray-7);
    font-family: var(--font-nunito);
    font-size: var(--font-size-regular);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}