.wrapper {
    display: flex;
    height: 33px;
    padding: 3px;
    align-items: center;
    gap: 7px;
    border-radius: 8px;
    border: none;
    background-color: transparent;
}

.active {
    cursor: pointer;
}

.inactive {
    cursor: auto;
}

.icon {
    height: 100%;
    display: flex;
    box-sizing: border-box;
}