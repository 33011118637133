.wrapper {
    display: flex;
    height: 33px;
    padding: 2px 10px;
    align-items: center;
    gap: 7px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
}

.wrapper_small {
    height: 26px;
}

.active {
    background: var(--color-purple2);
}

.active:hover {
    background: var(--color-purple);
}

.active:active {
    background-color: var(--color-magenta);
}

.inactive {
    background: var(--color-gray-2);
    cursor: auto;
}

.text {
    color: #FFF;
    font-family: var(--font-nunito);
    font-size: var(--font-size-regular);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
}

.text_small {
    font-size: var(--font-size-small);
}

.icon {
    height: 100%;
    display: flex;
    box-sizing: border-box;
    padding: 5px 0;
}