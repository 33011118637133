.button {
    display: flex;
    width: 401px;
    height: 44px;
    margin-top: 25px;
    padding: 5px 12px;
    justify-content: center;
    align-items: center;
    border-radius: var(--br-mini-5);
    background: linear-gradient(274deg, var(--color-magenta) -30.11%, #164193 145.97%);
    color: var(--color-white);
    font-family: var(--font-nunito);
    font-size: var(--font-size-regular);
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}

.global_error {
    color: #f44336;
    font-family: var(--font-nunito);
    font-size: var(--font-size-medium);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
}