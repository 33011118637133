.login_page {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: flex-start;
    background-color: var(--color-white);
}

.login_part {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    align-items: center;
    width: 500px;
    margin: 0 50px;
    height: 100%;
    justify-content: center;
    padding: 0 80px;
    box-sizing: border-box;
}

.login_section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 248px;
}

.ad_part {
    position: relative;
    overflow: hidden;
    padding-left: 100px;
    height: 100%;
    box-sizing: border-box;
    flex: 1;
    background: linear-gradient(323deg, var(--color-magenta) 1.85%, #164193 103.67%);
}

.login_form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
}

.text_content {
    width: 100%;
    flex-shrink: 0;
    padding: 60px 100px 0 0;
    margin-bottom: 100px;
    box-sizing: border-box;
}

.open_quotation {
    color: rgba(255, 255, 255, 0.56);
    font-family: var(--font-nunito);
    font-size: 150px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.main_text {
    color: var(--color-white);
    font-family: var(--font-nunito);
    font-size: var(--font-size-medium);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.signature {
    width: 401px;
    color: rgba(255, 255, 255, 0.56);
    text-align: center;
    font-family: var(--font-nunito);
    font-size: var(--font-size-regular);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.image_content img{
    display: block;
    width: 100%;
    flex-shrink: 0;
    border-radius: 40px;
}

.welcome_back {
    color: var(--color-near-black);
    font-family: var(--font-arciform-sans);
    font-size: var(--font-size-very-large);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.gray_text {
    color: var(--color-gray-6);
    font-family: var(--font-nunito);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.textfield_wrapper {
    width: 401px;
    height: 52px;
    flex-shrink: 0;
    border-radius: 16px;
    background: #EEE;
}

.login_input {
    width: 401px;
    padding-left: 20px;
}

.input_placeholder {
    color: var(--color-gray-6);
    font-family: var(--font-nunito);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.show_password {
    position: absolute;
    right: 10px;
    top: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
}


.login_footer {
    color: var(--color-gray-6);
    text-align: center;
    font-family: var(--font-nunito);
    font-size: var(--font-size-regular);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.sign_up_link {
    color: var(--color-purple);
    font-family: var(--font-nunito);
    font-size: var(--font-size-regular);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

