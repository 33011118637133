.content_wrapper {
    display: flex;
    gap: 20px;
    width: 100%;
}

.cards_wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-grow: 0.5;
    flex-shrink: 0.5;
    flex-basis: 100%;
}

.wrapper {
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid #BBB;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex: 1 0 0;
}

.short_name {
    align-self: stretch;
    color: var(--color-gray-6);
    font-family: var(--font-nunito);
    font-size: var(--font-size-small);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
}

.qualification_controls {
    display: flex;
    align-items: flex-start;
    gap: 22px;
}

.description {
    align-self: stretch;
    color: var(--color-near-black);
    font-family: var(--font-nunito);
    font-size: var(--font-size-medium);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    border: none;
    border-radius: 0;
    outline: none;
    background: transparent;
    padding: 0;
    margin: 0;
    width: 100%;
    line-break: anywhere;
    resize: vertical;
}

.unassessed_wrapper {
    display: flex;
    gap: 20px;
    margin-top: 55px;
}

.dropdown {
    height: 20px;
}

.uncovered_text {
    color: var(--color-near-black);
    font-family: var(--font-nunito);
    font-size: var(--font-size-regular);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    margin-top: 0;
    margin-bottom: 5px;
}

.list_element {
    padding: 0;
    margin: 0;
}

.unassessed_qualification {
    display: flex;
    flex-wrap: wrap;
}

.unassessed_qualification {
    margin: 3px;
}

.inactive_button {
    cursor: auto;
}