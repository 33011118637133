.header {
    padding: 20px 30px 20px 30px;
    gap: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.logo_block {
    width: 83px;
    height: 34px;
}

.account_menu {
    padding: 0 25px 0 25px;
    gap: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.generic_button {
    gap: 8px;
    border-radius: 100px;
}

.generic_avatar {
    width: 40px;
    height: 40px;
    padding: 10px 5.91px 4.37px 5.91px;
    gap: 0;
    border-radius: 100px;
}
