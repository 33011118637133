.page_wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
}

.right_layout {
    display: flex;
    padding: 40px 60px 20px 60px;
    flex-direction: column;
    align-items: flex-start;
    gap: 29px;
    flex: 1 0 0;
    align-self: stretch;
    width: 100%;
    overflow: auto;
    background: white;
}

.main_wrapper {
    display: flex;
    width: 90%;
    flex-direction: column;
    align-items: flex-start;
    gap: 17px;
}

.wizard_controls {
    display: flex;
    gap: 10px;
    align-self: stretch;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
    width: 100%;
}